import JwtService from './jwtService';


/**
 * This is responsible for the axios instance configuration
 */
import axios from 'axios';

const queryParams = new URLSearchParams(window.location.search);
export const uid = queryParams.get('runAs'); // Run as to add in the query parameter
// Base axios instance creation. Default parameter and configuration can be added here
export const baseApi = axios.create();
 
// Request interception
axios.interceptors.request.use(async (requestConfig) => {
  // If needed, user token can be added here
  if (uid) {
    requestConfig.params = {...requestConfig.params, uid};
  }
  return requestConfig;
});
 


export default JwtService;
