/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  management: ['admin', 'management'],
  teamlead: ['admin', 'management', 'teamlead'],
  staff: ['admin', 'management', 'teamlead', 'staff'],
  onlyGuest: [],
};

export default authRoles;
