import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  
  {
    id: 'dashboards',
    title: 'Dashboards',
    translate: 'DASHBOARDS',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      {
        id: 'my-dashboard',
        title: 'My Dashboard',
        translate: 'MY_DASHBOARD',
        type: 'item',
        url: '/apps/dashboards/personal',
      },
      {
        id: 'team-dashboard',
        title: 'Team Dashboard',
        translate: 'TEAM_DASHBOARD',
        type: 'item',
        url: '/apps/dashboards/team',
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin Panel',
    translate: 'ADMIN_PANEL',
    type: 'item',
    icon: 'admin_panel_settings',
    url: '/apps/admin',
  },
  /*{
    id: 'summary',
    title: 'Summary',
    translate: 'SUMMARY',
    type: 'item',
    icon: 'remove_red_eye',
    url: '/apps/summary',
  },*/
  {
    id: 'calendar',
    title: 'Calendar',
    translate: 'CALENDAR',
    type: 'item',
    icon: 'today',
    url: '/apps/calendar',
  },
]


export default navigationConfig;
