const forestGreen = {
  50: '#E5F3F1',
  100: '#CCE7E3',
  200: '#B2DBD6',
  300: '#99CFC7',
  400: '#80C2BA',
  500: '#66B6AC',
  600: '#4CAA9E',
  700: '#339E91',
  800: '#199283',
  900: '#008675',
  A100: '#ffffff',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'dark',
};
      
export default forestGreen;
      