import { authRoles } from 'app/auth';
import { lazy } from 'react';

const TeamDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.teamlead,
  routes: [
    {
      path: '/apps/dashboards/team',
      component: lazy(() => import('./TeamDashboardApp')),
    },
  ],
};

export default TeamDashboardAppConfig;
