import PersonalDashboardAppConfig from './dashboards/personal/PersonalDashboardAppConfig';
import TeamDashboardAppConfig from './dashboards/team/TeamDashboardAppConfig';
import SummaryAppConfig from './summary/SummaryAppConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import ArrangementsAppConfig from './admin/notes/ArrangementsAppConfig';

const appsConfigs = [
  PersonalDashboardAppConfig,
  TeamDashboardAppConfig,
  SummaryAppConfig,
  CalendarAppConfig,
  ArrangementsAppConfig
];

export default appsConfigs;
