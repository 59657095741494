const reflexBlue = {
  50: '#E6E8F3',
  100: '#CCD0E7',
  200: '#B3B9DC',
  300: '#99A1D0',
  400: '#808AC4',
  500: '#6672B8',
  600: '#4D5BAD',
  700: '#3343A1',
  800: '#1A2C95',
  900: '#001489',
  A100: '#ffffff',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'dark',
};
  
export default reflexBlue;
  