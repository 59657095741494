import React from 'react';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader() {
  const user = useSelector(({ auth }) => auth.user);

  return (
    <StyledAppBar
      className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
      color="primary"
      position="static"
    >
      <Typography
        className="username text-18 whitespace-nowrap font-semibold mb-4"
        color="inherit"
      >
        {user?.firstName}
      </Typography>
      <Typography
        className="email text-13 opacity-50 whitespace-nowrap font-medium"
        color="inherit"
      >
        {user.email}
      </Typography>
      {/* <Typography
        className="email text-13 opacity-50 whitespace-nowrap font-medium"
        color="inherit"
      >
        {user.role.toString()}
        {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
      </Typography> */}
      <div className="flex items-center justify-center absolute bottom-0 -mb-44">
        <Avatar
          alt="user photo"
          className="avatar w-72 h-72 p-8 box-content"
          src={'assets/images/avatars/profile.jpg'}
        />
      </div>
    </StyledAppBar>
  );
}

export default UserNavbarHeader;
