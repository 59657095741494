import FuseUtils from '@fuse/utils';
import mock from '../mock';

const arrangementsDB = {
  arrangements: [
    {
      id: '5739d1fb4d27bc5341fd33s1',
      from: '2021-10-25T04:01:06.587Z',
      to: '2021-10-30T04:01:06.587Z',
      departments: ['5725a6809fdd915739187ed5'],
      series: [60,60,40,40],
      configId: 1
    },
    {
      id: '5739d1fbaac9710256574208',
      from: '2021-10-25T04:01:06.587Z',
      to: '2021-10-30T04:01:06.587Z',
      departments: ['5725a680606588342058356d'],
      series: [50,70,30,30],
      configId: 1
    },
    {
      id: '5739d1fbf4e68a871a3c9ab8',
      from: '2021-10-25T04:01:06.587Z',
      to: '2021-10-30T04:01:06.587Z',
      departments: ['5725a6802d10e277a0f35739'],
      series: [50,70,30,30],
      configId: 1
    },
    {
      id: '5739d1fbf2726fe3e5e5014a',
      from: '2021-10-25T04:01:06.587Z',
      to: '2021-10-30T04:01:06.587Z',
      departments: ['5725a68031fdbb1db2c1af47'],
      series: [30,20,30,20],
      configId: 1
    },
    {
      id: '5739d1fb843f747d5dc0e42a',
      from: '2021-11-01T04:01:06.587Z',
      to: '2021-11-10T04:01:06.587Z',
      departments: ['5725a6809fdd915739187ed5'],
      series: [50,70,30,30],
      configId: 2
    },
    {
      id: '5739d1fbb786bea648179ece',
      from: '2021-11-01T04:01:06.587Z',
      to: '2021-11-10T04:01:06.587Z',
      departments: ['5725a6802d10e277a0f35739'],
      series: [50,70,30,30],
      configId: 2
    },
    {
      id: '5739d1fbcf298e41a75f3941',
      from: '2021-11-01T04:01:06.587Z',
      to: '2021-11-10T04:01:06.587Z',
      departments: ['5725a680606588342058356d'],
      series: [50,70,30,30],
      configId: 2
    },
    {
      id: '5739d1fb47d9bac96ec0d54d',
      from: '2021-11-10T04:01:06.587Z',
      to: '2021-11-20T04:01:06.587Z',
      departments: ['5725a6809fdd915739187ed5'],
      series: [50,70,30,30],
      configId: 3
    },
    {
      id: '5739d1fb2fe509295e0847b5',
      from: '2021-11-10T04:01:06.587Z',
      to: '2021-11-20T04:01:06.587Z',
      departments: ['5725a6802d10e277a0f35739'],
      series: [50,70,30,30],
      configId: 3
    },
    {
      id: '5739d1fb2efbc0e3e8c30c4d',
      from: '2021-11-20T04:01:06.587Z',
      to: '2021-11-30T04:01:06.587Z',
      departments: ['5725a6809fdd915739187ed5'],
      series: [50,70,30,30],
      configId: 4
    },
    {
      id: '5739d1fbeac05b8bcc2959cd',
      from: '2021-12-01T04:01:06.587Z',
      to: '2021-12-10T04:01:06.587Z',
      departments: ['5725a6809fdd915739187ed5'],
      series: [50,70,30,30],
      configId: 5
    },
  ],
  organizations: [
    {
      id: 1,
      unitName: 'COO  & Intermediaries Asia',
      costCenter: '1000001',
      resourceCount: 30,
      division: {
        id: 1,
        divisionName: 'COO & Intermediaries Asia',
        description: null,
        country: null
      }
    },
    {
      id: 2,
      unitName: 'COO  & Intermediaries Asia 2',
      costCenter: '1000001',
      resourceCount: 30,
      division: {
        id: 1,
        divisionName: 'COO & Intermediaries Asia 2',
        description: null,
        country: null
      }
    }
  ],
  configurations: [
    {
      id: 1,
      from: '2021-10-25T04:01:06.587Z',
      to: '2021-10-30T04:01:06.587Z',
    },
    {
      id: 2,
      from: '2021-11-01T04:01:06.587Z',
      to: '2021-11-10T04:01:06.587Z',
    },
    {
      id: 3,
      from: '2021-11-10T04:01:06.587Z',
      to: '2021-11-20T04:01:06.587Z',
    },
    {
      id: 4,
      from: '2021-11-20T04:01:06.587Z',
      to: '2021-11-30T04:01:06.587Z',
    },
    {
      id: 5,
      from: '2021-12-01T04:01:06.587Z',
      to: '2021-12-10T04:01:06.587Z'
    }
  ]
};

mock.onGet('/api/arrangements-app/arrangements').reply(() => {
  return [200, arrangementsDB.arrangements];
});

mock.onGet('/api/arrangements-app/configuration').reply(() => {
  return [200, arrangementsDB.configurations];
});

mock.onPost('/api/arrangements-app/create-arrangement').reply(request => {
  const data = JSON.parse(request.data);
  const newArrangement = {
    ...data.arrangement,
    id: FuseUtils.generateGUID()
  };
  arrangementsDB.arrangements = [newArrangement, ...arrangementsDB.arrangements];
  return [200, newArrangement];
});

mock.onPost('/api/arrangements-app/update-arrangement').reply(request => {
  const { arrangement } = JSON.parse(request.data);

  arrangementsDB.newArrangements = arrangementsDB.arrangements.map(_arrangement => {
    if (arrangement.id === _arrangement.id) {
      return arrangement;
    }
    return _arrangement;
  });

  return [200, arrangement];
});

mock.onPost('/api/arrangements-app/update-departments').reply(request => {
  const data = JSON.parse(request.data);

  arrangementsDB.labels = data.labels;

  return [200, arrangementsDB.labels];
});

mock.onPost('/api/arrangements-app/remove-arrangement').reply(request => {
  const data = JSON.parse(request.data);

  arrangementsDB.arrangements = arrangementsDB.arrangements.filter(arrangement => data.arrangementId !== arrangement.id);

  return [200, data.arrangementId];
});
