import React, { useEffect } from 'react';
import { styled, darken } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';

const Root = styled('div')(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
    theme.palette.primary.dark,
    0.5
  )} 100%)`,
  color: theme.palette.primary.contrastText,

  '& .Login-leftSection': {},

  '& .Login-rightSection': {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function Login() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(submitLogin());
  }, [])
  return (
    <Root className="flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24">
      <div
        className={clsx(
          'flex flex-1 flex-col items-center justify-center p-24',
        )}
      >
        <Typography
          className="text-13 sm:text-20 mb-16"
          color="textSecondary"
        >
          Logging in...
        </Typography>
        <LinearProgress
          className="w-192 sm:w-320 max-w-full rounded-2"
          color="secondary"
        />
      </div>
    </Root>
  );
}

export default Login;
