const royalBlue = {
  50: '#E8E9EE',
  100: '#D0D2DD',
  200: '#B9BBCC',
  300: '#A1A5BB',
  400: '#898FAA',
  500: '#727899',
  600: '#5B6188',
  700: '#434B77',
  800: '#2C3566',
  900: '#141E55',
  A100: '#ffffff',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'dark',
};
    
export default royalBlue;
    