import { authRoles } from 'app/auth';
import { lazy } from 'react';

const SummaryAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.management,
  routes: [
    {
      path: '/apps/summary',
      component: lazy(() => import('./SummaryApp')),
    }
  ],
};

export default SummaryAppConfig;
