import { authRoles } from 'app/auth';
import { lazy } from 'react';

const ArrangementsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: '/apps/admin/new',
      component: lazy(() => import('./NewArrangement')),
    },
    {
      path: '/apps/admin/:type?/:departmentId?',
      component: lazy(() => import('./ArrangementsApp')),
    },
  ],
};

export default ArrangementsAppConfig;
