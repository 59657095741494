import mock from '../mock';

const teamDashboardAppDB = {
  widgets: [
    {
      id: 'widget1',
      series: {
        week: [
          {
            name: 'WFO',
            data: [9, 10, 5, 8, 10, 7, 9]
          },
          {
            name: 'WFH',
            data: [10, 9, 8, 5, 7, 9, 10]
          },
          {
            name: 'PWFO',
            data: [20, 2, 4, 12, 18, 3, 1]
          },
          {
            name: 'PWFH',
            data: [5, 10, 7, 8, 20, 8, 19]
          },
        ],
        month: [
          {
            name: 'WFO',
            data: [9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 10, 20]
          },
          {
            name: 'WFH',
            data: [10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 9, 3]
          },
          {
            name: 'PWFO',
            data: [20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 43, 21]
          },
          {
            name: 'PWFH',
            data: [5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 30, 20]
          }
        ],
      },
      options: {
        chart: {
          type: 'bar',
          height: '100%',
          // stacked: true,
          foreColor: '#999',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          strokeColor: '#fff',
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        grid: {
          position: 'back'
        },
        legend: {
          show: true
        },
        fill: {
          type: 'solid',
          opacity: 0.7
        },
        tooltip: {
          followCursor: true,
          theme       : 'dark',
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        }
      },
      categories: {
        week: [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat'
        ],
        month: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30'
        ]
      }
    },
    {
      id: 'widget2',
      title: 'Team Arrangement',
      table: {
        columns: [
          {
            id: 'avatar',
            title: ''
          },
          {
            id: 'name',
            title: 'Name'
          },
          {
            id: 'type',
            title: 'Arrangement'
          },
          {
            id: 'from',
            title: 'From'
          },
          {
            id: 'to',
            title: 'To'
          },
          {
            id: 'action',
          }
        ],
        rows: [
          {
            id: 1,
            cells: [
              {
                id: 'avatar',
                value: 'assets/images/avatars/james.jpg'
              },
              {
                id: 'name',
                value: 'Mahendra Kapadne',
                classes: 'font-semibold',
                icon: ''
              },
              {
                id: 'type',
                value: 'WFO',
                classes: 'bg-blue text-white',
                icon: ''
              },
              {
                id: 'from',
                value: '2021/09/10',
                classes: '',
                icon: ''
              },
              {
                id: 'to',
                value: '2021/09/20',
                classes: '',
                icon: ''
              },
              {
                id: 'action',
              }
            ]
          },
          {
            id: 2,
            cells: [
              {
                id: 'avatar',
                value: 'assets/images/avatars/garry.jpg'
              },
              {
                id: 'name',
                value: 'Pramit Jain',
                classes: 'font-semibold',
                icon: ''
              },
              {
                id: 'type',
                value: 'WFH',
                classes: 'bg-red text-white',
                icon: ''
              },
              {
                id: 'from',
                value: '2021/09/10',
                classes: '',
                icon: ''
              },
              {
                id: 'to',
                value: '2021/09/20',
                classes: '',
                icon: ''
              },
              {
                id: 'action',
              }
            ]
          },
          {
            id: 3,
            cells: [
              {
                id: 'avatar',
                value: 'assets/images/avatars/katherine.jpg'
              },
              {
                id: 'name',
                value: 'Shubha Tripati',
                classes: 'font-semibold',
                icon: ''
              },
              {
                id: 'type',
                value: 'PWFH',
                classes: 'bg-green text-white',
                icon: ''
              },
              {
                id: 'from',
                value: '2021/09/10',
                classes: '',
                icon: ''
              },
              {
                id: 'to',
                value: '2021/09/20',
                classes: '',
                icon: ''
              },
              {
                id: 'action',
              }
            ]
          },
          {
            id: 4,
            cells: [
              {
                id: 'avatar',
                value: 'assets/images/avatars/andrew.jpg'
              },
              {
                id: 'name',
                value: 'Karl Santos',
                classes: 'font-semibold',
                icon: ''
              },
              {
                id: 'type',
                value: 'PWFO',
                classes: 'bg-orange text-white',
                icon: ''
              },
              {
                id: 'from',
                value: '2021/09/10',
                classes: '',
                icon: ''
              },
              {
                id: 'to',
                value: '2021/09/20',
                classes: '',
                icon: ''
              },
              {
                id: 'action',
              }
            ]
          },
          {
            id: 5,
            cells: [
              {
                id: 'avatar',
                value: 'assets/images/avatars/jane.jpg'
              },
              {
                id: 'name',
                value: 'Joyce Ma',
                classes: 'font-semibold',
                icon: ''
              },
              {
                id: 'type',
                value: 'WFO',
                classes: 'bg-blue text-white',
                icon: ''
              },
              {
                id: 'from',
                value: '2021/09/10',
                classes: '',
                icon: ''
              },
              {
                id: 'to',
                value: '2021/09/20',
                classes: '',
                icon: ''
              },
              {
                id: 'action',
              }
            ]
          }
        ]
      }
    },
  ],
  team: [
    {
      id: 1,
      name: 'Mahendra Kapadne'
    },
    {
      id: 2,
      name: 'Pramit Jain'
    },
    {
      id: 3,
      name: 'Shubha Tripati'
    },
    {
      id: 4,
      name: 'Karl Santos'
    },
    {
      id: 5,
      name: 'Joyce Ma'
    }
  ]
};

mock.onGet('/api/team-dashboard-app/widgets').reply(() => {
  return [200, teamDashboardAppDB.widgets];
});

mock.onGet('/api/team-dashboard-app/data').reply(() => {
  return [200, teamDashboardAppDB.team];
});
