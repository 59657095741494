import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <img
        alt="logo"
        className="logo-icon w-32 h-32"
        src="assets/images/logos/juliusbaer-building.png"
      />
      <Typography
        className="logo-text text-14 leading-none mx-12 font-medium"
        color="inherit"
      >
        WORKPLACE 360
      </Typography>
    </Root>
  );
}

export default Logo;
