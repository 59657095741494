import FuseUtils from '@fuse/utils';
import mock from '../mock';
import formatISO from 'date-fns/formatISO';

function setDate(year, month, date, hours, minutes, seconds) {
  return formatISO(new Date(year, month, date, hours || '', minutes || '', seconds|| ''));
}

const calendarDB = {
  events: [
    {
      id: 0,
      title: 'WFH',
      allDay: true,
      start: setDate(2021, 9, 1),
      end: setDate(2021, 9, 2)
    },
    {
      id: 1,
      title: 'WFO',
      allDay: true,
      start: setDate(2021, 9, 4),
      end: setDate(2021, 9, 9)
    },
    {
      id: 5,
      title: 'WFO',
      allDay: true,
      start: setDate(2021, 9, 11),
      end: setDate(2021, 9, 13),
    },
    {
      id: 11,
      title: 'WFH',
      allDay: true,
      start: setDate(2021, 9, 13),
      end: setDate(2021, 9, 16)
    },
    {
      id: 12,
      title: 'WFO',
      allDay: true,
      start: setDate(2021, 9, 18),
      end: setDate(2021, 9, 21)
    },
    {
      id: 13,
      title: 'WFH',
      allDay: true,
      start: setDate(2021, 9, 21),
      end: setDate(2021, 9, 23)
    },
    {
      id: 14,
      title: 'WFH',
      allDay: true,
      start: setDate(2021, 9, 25),
      end: setDate(2021, 9, 26)
    },
    {
      id: 16,
      title: 'WFO',
      allDay: true,
      start: setDate(2021, 9, 26),
      end: setDate(2021, 9, 29)
    },
    {
      id: 17,
      title: 'WFH',
      allDay: true,
      start: setDate(2021, 9, 29),
      end: setDate(2021, 9, 30)
    },
    {
      id: 18,
      title: 'WFO',
      allDay: true,
      start: setDate(2021, 10, 1),
      end: setDate(2021, 10, 4)
	}
  ]
};

mock.onGet('/api/calendar-app/events').reply(config => {
  return [200, calendarDB.events];
});

mock.onPost('/api/calendar-app/add-event').reply(request => {
  const data = JSON.parse(request.data);
  const newEvent = {
    ...data.newEvent,
    id: FuseUtils.generateGUID()
  };
  calendarDB.events = [...calendarDB.events, newEvent];
  return [200, newEvent];
});

mock.onPost('/api/calendar-app/update-event').reply(request => {
  const data = JSON.parse(request.data);

  calendarDB.events = calendarDB.events.map(event => {
    if (data.event.id === event.id) {
      return data.event;
    }
    return event;
  });

  return [200, data.event];
});

mock.onPost('/api/calendar-app/remove-event').reply(request => {
  const data = JSON.parse(request.data);
  const event = calendarDB.events.find(_event => data.eventId === _event.id);
  calendarDB.events = calendarDB.events.filter(_event => _event.id !== event.id);

  return [200, event];
});
