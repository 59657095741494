const carmineRed = {
  50: '#F5E8EA',
  100: '#EAD1D5',
  200: '#E0BBC1',
  300: '#D5A4AC',
  400: '#CB8D97',
  500: '#C17682',
  600: '#B65F6D',
  700: '#AC4959',
  800: '#A13244',
  900: '#971B2F',
  A100: '#ffffff',
  A200: '#daf7ff',
  A400: '#a7ecff',
  A700: '#8de6ff',
  contrastDefaultColor: 'light',
};
    
export default carmineRed;
    