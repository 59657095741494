import mock from '../mock';

const teamSummaryAppDB = {
  widgets: [
    {
      id: 'widget1',
      team: 'Front Applications Delivery & Management',
      series: {
        week: [
          {
            name: 'WFO',
            data: [9, 10, 5, 8, 10, 7, 9]
          },
          {
            name: 'WFH',
            data: [10, 9, 8, 5, 7, 9, 10]
          },
          {
            name: 'PWFO',
            data: [20, 2, 4, 12, 18, 3, 1]
          },
          {
            name: 'PWFH',
            data: [5, 10, 7, 8, 20, 8, 19]
          }
        ],
        month: [
          {
            name: 'WFO',
            data: [9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 10, 20]
          },
          {
            name: 'WFH',
            data: [10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 9, 3]
          },
          {
            name: 'PWFO',
            data: [20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 43, 21]
          },
          {
            name: 'PWFH',
            data: [5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 30, 20]
          }
        ]
      },
      options: {
        chart: {
          type: 'bar',
          height: '100%',
          // stacked: true,
          foreColor: '#999',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          strokeColor: '#fff',
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        grid: {
          position: 'back'
        },
        legend: {
          show: true
        },
        fill: {
          type: 'solid',
          opacity: 0.7
        },
        tooltip: {
          followCursor: true,
          theme       : 'dark',
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        }
      },
      categories: {
        week: [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat'
        ],
        month: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30'
        ]
      }
    },
    {
      id: 'widget2',
      team: 'Data Integration & Reporting',
      series: {
        week: [
          {
            name: 'WFO',
            data: [9, 8, 5, 10, 7, 9, 10]
          },
          {
            name: 'WFH',
            data: [9, 10, 5, 8, 10, 7, 9]
          },
          {
            name: 'PWFO',
            data: [8, 19, 5, 10, 7, 8, 20]
          },
          {
            name: 'PWFH',
            data: [4, 12, 20, 3, 1, 2, 18]
          }
        ],
        month: [
          {
            name: 'WFO',
            data: [10, 9, 8, 5, 7, 9, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 9, 3, 1, 10, 10, 9, 8, 5, 7, 9, 10]
          },
          {
            name: 'WFH',
            data: [8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 10, 20, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5]
          },
          {
            name: 'PWFO',
            data: [7, 8, 20, 8, 19, 30, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 5, 10]
          },
          {
            name: 'PWFH',
            data: [1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 20, 2, 4, 12, 18, 3, 4, 12, 18, 3, 1, 20, 3, 1, 43, 21, 2, 4, 12, 18]
          }
        ]
      },
      options: {
        chart: {
          type: 'bar',
          height: '100%',
          // stacked: true,
          foreColor: '#999',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          strokeColor: '#fff',
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        grid: {
          position: 'back'
        },
        legend: {
          show: true
        },
        fill: {
          type: 'solid',
          opacity: 0.7
        },
        tooltip: {
          followCursor: true,
          theme       : 'dark',
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        }
      },
      categories: {
        week: [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat'
        ],
        month: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30'
        ]
      }
    },
    {
      id: 'widget3',
      team: 'Core Banking & Transactions',
      series: {
        week: [
          {
            name: 'WFO',
            data: [5, 10, 7, 8, 20, 8, 19]
          },
          {
            name: 'WFH',
            data: [20, 2, 4, 12, 18, 3, 1]
          },
          {
            name: 'PWFO',
            data: [10, 9, 8, 5, 7, 9, 10]
          },
          {
            name: 'PWFH',
            data: [9, 10, 5, 8, 10, 7, 9]
          }
        ],
        month: [
          {
            name: 'WFO',
            data: [5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8, 20, 8, 19, 20, 8, 19, 30, 20, 5, 10, 7, 8, 20, 8, 19, 5, 10, 7, 8]
          },
          {
            name: 'WFH',
            data: [9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 9, 10, 5, 8, 10, 7, 9, 10, 20]
          },
          {
            name: 'PWFO',
            data: [12, 18, 3, 1, 43, 21, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4, 12, 18, 3, 1, 20, 2, 4]
          },
          {
            name: 'PWFH',
            data: [10, 9, 8, 5, 7, 9, 10, 10, 9, 8, 5, 7, 7, 9, 10, 10, 9, 8, 5, 7, 9, 10, 9, 9, 10, 10, 9, 8, 5, 3]
          }
        ]
      },
      options: {
        chart: {
          type: 'bar',
          height: '100%',
          // stacked: true,
          foreColor: '#999',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          strokeColor: '#fff',
          strokeWidth: 3,
          strokeOpacity: 1,
          fillOpacity: 1,
          hover: {
            size: 6
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        grid: {
          position: 'back'
        },
        legend: {
          show: true
        },
        fill: {
          type: 'solid',
          opacity: 0.7
        },
        tooltip: {
          followCursor: true,
          theme       : 'dark',
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        }
      },
      categories: {
        week: [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat'
        ],
        month: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30'
        ]
      }
    },
  ],
  teams: [
    {
      id: 1,
      name: 'All'
    },
    {
      id: 2,
      name: 'Front Applications Delivery & Management'
    },
    {
      id: 3,
      name: 'Data Integration & Reporting'
    },
    {
      id: 4,
      name: 'Core Banking & Transactions'
    }
  ]
};

mock.onGet('/api/summary-app/widgets').reply(() => {
  return [200, teamSummaryAppDB.widgets];
});

mock.onGet('/api/summary-app/teams').reply(() => {
  return [200, teamSummaryAppDB.teams];
});
