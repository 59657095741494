const locale = {
  APPLICATIONS: 'Programlar',
  DASHBOARDS: 'Dashboards',
  MY_DASHBOARD: 'My Dashboard',
  TEAM_DASHBOARD: 'Team Dashboard',
  ADMIN_PANEL: 'Admin Panel',
  SUMMARY: 'Summary',
  CALENDAR: 'Calendar',
  MAIL: 'Mail',
  TODO: 'To-Do',
  CHAT: 'Chat',
  NOTES: 'Notes'
};

export default locale;
