import './db/team-dashboard-db'; 
import './db/admin-db';
import './db/team-summary-db';
import './db/calendar-db';
import './db/arrangement-db';
import './db/academy-db';

// import './db/auth-db';
// import './db/capacity-db';
// import './db/schedule-db';
// import './db/my-team-arrangement-db';
// import './db/arrangements-db';
// import './db/shift-arrangements-db';

// import './db/team-schedule-db';
// import './db/teams-db';

// import './db/organization-db';
import history from '@history';
import mock from './mock';

mock.onAny().passThrough();

if (module?.hot?.status() === 'apply') {
  const { pathname } = history.location;
  history.push('/loading');
  history.push({ pathname });
}
