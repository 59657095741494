import mock from '../mock';

const adminDB = {
  arrangements: [
    {
      id: 1,
      cells: [
        {
          id: 'arrangement',
          values: [
            {
              percent: 30,
              classes: 'text-blue',
              type: 'WFO'
            },
            {
              percent: 40,
              classes: 'text-red',
              type: 'WFH'
            },
            {
              percent: 10,
              classes: 'text-orange',
              type: 'PWFO'
            },
            {
              percent: 20,
              classes: 'text-green',
              type: 'PWFH'
            }
          ],
        },
        {
          id: 'from',
          value: '2021/09/10',
          classes: '',
          icon: ''
        },
        {
          id: 'to',
          value: '2021/09/20',
          classes: '',
          icon: ''
        },
        {
          id: 'action',
        }
      ]
    },
    {
      id: 2,
      cells: [
        {
          id: 'arrangement',
          values: [
            {
              percent: 20,
              classes: 'text-blue',
              type: 'WFO'
            },
            {
              percent: 10,
              classes: 'text-red',
              type: 'WFH'
            },
            {
              percent: 40,
              classes: 'text-orange',
              type: 'PWFO'
            },
            {
              percent: 30,
              classes: 'text-green',
              type: 'PWFH'
            }
          ],
        },
        {
          id: 'from',
          value: '2021/09/21',
          classes: '',
          icon: ''
        },
        {
          id: 'to',
          value: '2021/09/30',
          classes: '',
          icon: ''
        },
        {
          id: 'action',
        }
      ]
    },
    {
      id: 3,
      cells: [
        {
          id: 'arrangement',
          values: [
            {
              percent: 0,
              classes: 'text-blue',
              type: 'WFO'
            },
            {
              percent: 40,
              classes: 'text-red',
              type: 'WFH'
            },
            {
              percent: 10,
              classes: 'text-orange',
              type: 'PWFO'
            },
            {
              percent: 50,
              classes: 'text-green',
              type: 'PWFH'
            }
          ],
        },
        {
          id: 'from',
          value: '2021/10/01',
          classes: '',
          icon: ''
        },
        {
          id: 'to',
          value: '2021/10/10',
          classes: '',
          icon: ''
        },
        {
          id: 'action',
        }
      ]
    },
    {
      id: 4,
      cells: [
        {
          id: 'arrangement',
          values: [
            {
              percent: 40,
              classes: 'text-blue',
              type: 'WFO'
            },
            {
              percent: 20,
              classes: 'text-red',
              type: 'WFH'
            },
            {
              percent: 30,
              classes: 'text-orange',
              type: 'PWFO'
            },
            {
              percent: 10,
              classes: 'text-green',
              type: 'PWFH'
            }
          ],
        },
        {
          id: 'from',
          value: '2021/10/11',
          classes: '',
          icon: ''
        },
        {
          id: 'to',
          value: '2021/10/20',
          classes: '',
          icon: ''
        },
        {
          id: 'action',
        }
      ]
    },
    {
      id: 5,
      cells: [
        {
          id: 'arrangement',
          values: [
            {
              percent: 40,
              classes: 'text-blue',
              type: 'WFO'
            },
            {
              percent: 10,
              classes: 'text-red',
              type: 'WFH'
            },
            {
              percent: 40,
              classes: 'text-orange',
              type: 'PWFO'
            },
            {
              percent: 10,
              classes: 'text-green',
              type: 'PWFH'
            }
          ],
        },
        {
          id: 'from',
          value: '2021/10/21',
          classes: '',
          icon: ''
        },
        {
          id: 'to',
          value: '2021/10/30',
          classes: '',
          icon: ''
        },
        {
          id: 'action',
        }
      ]
    }
  ]
};

mock.onGet('/api/admin-app/arrangements').reply(() => {
  return [200, adminDB.arrangements];
});
